import { computed } from 'vue'
import { useAuthState } from '#imports'

export function useSession() {
  const { data } = useAuthState()

  const hasPreDxViewerPermission = computed(() => data.value?.permissions?.includes('users.predx_viewer'))
  const hasPreDxAnnotatorPermission = computed(() => data.value?.permissions?.includes('users.predx_annotator'))
  const hasPreHxPermission = computed(() => data.value?.permissions?.includes('users.prehx'))

  return {
    data,
    hasPreDxViewerPermission,
    hasPreDxAnnotatorPermission,
    hasPreHxPermission,
  }
}
